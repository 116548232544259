
#Arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
}

#BackgroundContainer {
    position: sticky;
    top: 0;
}

#Video {
    width: 100%;
    /* height: 100vh; */
}

#TextContainer {
    position: absolute;
    width: 700px;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    border: 4px solid #000000;
    padding: 40px;
}

#TextContainer h1 {
    font-size: 30px;
    font-weight: 600;
}

.GrayContainer {
    position: relative;
    background: #F1F1F1;
}

.WhiteContainer {
    position: relative;
    background: #FFFFFF;
}

.BlackContainer {
    position: relative;
    padding: 100px 0;
    background: #FFFFFF;
    text-align: center;
}

.BlackContainer h1 {
    font-size: 34px;
    color: #000000;
}

.BlackContainer p {
    margin-bottom: 50px;
    font-size: 20px;
    color: #333333;
}

.SuperImageContainer {
    position: absolute;
}

.SuperTextContainer.Left {
    left: 0;
    text-align: left;
}

.SuperImageContainer.Right {
    right: 0;
    text-align: right;
}

.SuperImageContainer img {
    height: 100%;
}

.SuperTextContainer {
    position: absolute;
    bottom: 10%;
}

.SuperTextContainer.Left {
    left: 0;
    text-align: left;
    max-width: 50%;
}

.SuperTextContainer.Right {
    right: 0;
    text-align: right;
    max-width: 50%;
}

.SuperText {
    font-size: 100px;
    font-weight: 900;
    line-height: 110px;
}

.SubSuperText {
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
    color: #888888;
}

.SuperTextContainer.Left .SubSuperText {
    padding-right: 20%;
}

.SuperTextContainer.Right .SubSuperText {
    padding-left: 20%;
}


@media only screen and (max-width: 992px) {

    #BackgroundContainer {
        position: relative;
        width: 100vw;
        height: 100vh;
    }

    #Video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    #TextContainer {
        position: absolute;
        width: 100%;
        text-align: center;
        background: none;
        box-shadow: none;
        border: none;
        padding: 10px;
    }

    #TextContainer h1 {
        font-size: 20px;
    }

    .GrayContainer .RegContainer{
        height: auto !important;
    }
    
    .WhiteContainer .RegContainer{
        height: auto !important;
    }
    
    .SuperImageContainer {
        position: relative;
    }
    
    .SuperTextContainer {
        position: relative;
        padding: 30px 10px;
    }
    
    .SuperImageContainer img {
        display: none;
    }

    .SuperText {
        font-size: 1.2rem;
        line-height: normal;
    }
    
    .SubSuperText {
        font-size: 1rem;
        line-height: normal;
    }
}