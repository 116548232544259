/* WebFont : Roboto */

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Thin/Roboto-Thin.woff') format('woff'),
        url('./media/font/Thin/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Light/Roboto-Light.woff') format('woff'),
        url('./media/font/Light/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Regular/Roboto-Regular.woff') format('woff'),
        url('./media/font/Regular/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Regular/Roboto-Regular.woff') format('woff'),
        url('./media/font/Regular/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Medium/Roboto-Medium.woff') format('woff'),
        url('./media/font/Medium/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Bold/Roboto-Bold.woff') format('woff'),
        url('./media/font/Bold/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Bold/Roboto-Bold.woff') format('woff'),
        url('./media/font/Bold/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./media/font/Black/Roboto-Black.woff') format('woff'),
        url('./media/font/Black/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}



/* Global Style */

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

body {
    font-family: 'Roboto', 'Open Sans', sans-serif, Helvetica;
    background: #FFFFFF;
    font-size: 14px;
    line-height: 1.6;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
a:active,
a:visited,
a:link {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

ul {
    list-style: none;
}

p, span {
    font-size: 1rem;
}

h1 {
    font-size: 2rem;

}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.4rem;
}

h5 {
    font-size: 1.2rem;
}

input::placeholder {
    color: hsl(220, 10%, 85%);
    font-size: 12px;
    font-weight: 200;
}

textarea::placeholder {
    color: hsl(220, 10%, 85%);
    font-size: 12px;
    font-weight: 200;
}


table {
    border-collapse: collapse;
    table-layout: fixed;
    border-bottom: 1px solid #000000;
}

th {
    position: relative;
    padding: 10px 50px;
    border-bottom: 1px solid #000000;
}

tr {
    border: none;
    text-align: left;
    cursor: pointer;
}

tr:hover {
    background: #F1F1F1;
}


td {
    padding: 10px 50px;
    border: none;
    border-collapse: collapse;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


blockquote{
    font-size: 20px;
    width: 100%;
    color: #888888;
    text-align: center;
}
  


/* Container and Wrapper */

.RegContainer {
    position: relative;
    margin: 0 auto;
    width: 90%;
}

.RegFixedContainer {
    position: relative;
    margin: 0 auto;
    width: 1200px;
}



/* Buttons */

.RegButton {
    
}



.TitleText {
    margin: 100px 0 10px 0;
    text-align: center;
}

.SubTitleText {
    margin-bottom: 80px;
    text-align: center;
}

.SubTitle {
    font-size: 16px;
}


/* Media query for SmartPhone */

@media only screen and (max-width: 992px) {

    .RegContainer {
        width: 100%;
        padding: 5%;
    }
    
    .RegFixedContainer {
        width: 100%;
        padding: 5%;
    }

}