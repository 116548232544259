#DownloadContainer {
    display: flex;
    width: 100%;
    min-height: 90vh;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;

    padding: 100px;
}


#DownloadLeft {
    flex-basis: 50%;
    height: 400px;
}

.DownloadBtn {

}

.DownloadBtn span {
    vertical-align: middle;
    cursor: pointer;
    line-height: 30px;
}

.DownloadBtn span:first-child {
    font-size: 18px;
    margin-right: 5px;
    text-indent: 14px;
}

.DownloadBtn span:hover {
    opacity: 0.8;
}

#DownloadRight {
    flex-basis: 50%;
}

#GitHub {
    height: 300px;
    text-align: center;
}

#GitHub img {
    width: 300px;
}


/* Media query for SmartPhone */

@media only screen and (max-width: 992px) {

    #GitHub img {
        width: 100%;
    }

}