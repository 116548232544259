.RegisterContainer {
    padding: 50px;
    margin-bottom: 50px;
    width: 100%;
    border: 1px solid #000000;
}

.RegisterContainer p {
    font-size: 1.1rem;
}

.RegisterQuestion {
    margin-top: 30px;
    text-decoration: underline;
}

#RegisterButtonContainer {
    margin: 0 auto;
    text-align: center;
}

#RegisterButtonContainer span {
    margin: 0 5px;
    padding: 10px 20px;
    background: #000000;
    border: 1px solid #000000;
    border-radius: 5px;
    color: #FFFFFF;
    line-height: 60px;
    cursor: pointer;
}

#RegisterButtonContainer span:hover {
    background: #FFFFFF;
    color: #000000;
}