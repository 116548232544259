#HeaderContainer {
    position: relative;
    background: #FFFFFF;
    width: 100%;
    height: 60px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

#LogoContainer {
    float: left;
    padding: 10px;
    line-height: 0;
}

#LogoContainer img{
    width: 40px;
    height: 40px;
    opacity: 1;
    cursor: pointer;
}

#LogoContainer img:hover{
    opacity: 0.7;
}

.HeaderMenu {
    margin: 0 10px;
    line-height: 60px;
    cursor: pointer;
}

.HeaderMenu:hover {
    border-bottom: 2px solid #000000;
}

#RegisterButton {
    float: right;
}

#RegisterButton span {
    padding: 10px 20px;
    background: #000000;
    border: 1px solid #000000;
    border-radius: 5px;
    line-height: 60px;
    color: #FFFFFF;
    cursor: pointer;
}

#RegisterButton span:hover {
    background: #FFFFFF;
    color: #000000;
}

/* Mobile Menu */

#MenuContainer {
    position: fixed;
    width: 100vw;
    background: #F1F1F1;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    overflow-y: scroll;
    overflow-x: hidden;
}

#MenuTop {
    text-align: center;
    background: #FFFFFF;
}

#MenuTop p {
    padding: 20px 0;
    font-size: 20px;
    font-weight: 600;
}

#MenuTop:hover {
    opacity: 0.8;
}


#MenuBody {
    padding: 40px 5% 5% 5%;
}

#MenuBody h4 {
    line-height: 3;
}

#MenuBody h4:hover {
    opacity: 0.8;;
}
