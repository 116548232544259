#FAQContainer {
    width: 100%;
    min-height: 90vh;
    margin: 0 auto;
}


.Question {
    font-size: 20px;
    font-weight: bold;
    line-height: 70px;
    cursor: pointer;
}

.Answer {
    padding: 0 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.Answer p {
}