#DemoContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
}

#DemoLeft {
    flex-basis: 40%;
    height: 600px;
    padding: 50px;
    text-align: center;
}

#DemoRight {
    flex-basis: 40%;
    height: 600px;
    padding: 50px;
    text-align: center;
}


.DemoImage {
    width: 500px;
    height: 280px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.DemoText {
    margin-top: 20px;
}

.DemoText h2 {
    font-size: 20px;
}

.DemoButton {
    margin-top: 20px;
}

.DemoButton span {
    display: inline-block;
    width: 500px;
    border: 1px solid #000000;
    border-radius: 5px;
    line-height: 50px;
    cursor: pointer;
}

.DemoButton span:hover {
    background: #000000;
    color: #FFFFFF;
}