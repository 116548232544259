#FeatureContainer {
    column-count: 4;
    column-gap: 10px;
}

.FeatureBlock {
    margin: 0;
    padding: 20px;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
    border: 1px solid #000000;
    border-style: dotted;
}
  
.FeatureBlock ul::first-line {
    text-decoration: underline;
}

.FeatureBlock li {
    text-indent: 14px;
}


/* Media query for SmartPhone */

@media only screen and (max-width: 992px) {

    #FeatureContainer {
        column-count: 1;
        column-gap: 10px;
    }
}