#FooterContainer {
    position: relative;
    background: #FFFFFF;
    width: 100%;
    height: 60px;
    z-index: 99;
}

#FooterContainer .RegContainer {
    border-top: 1px solid #000000;
}

#FooterContainer span {
    line-height: 60px;
}

.FooterMenu {
    margin-left: 50px;
    cursor: pointer;
}

.FooterMenu:hover {
    opacity: 0.5;
}

/* Media query for SmartPhone */

@media only screen and (max-width: 992px) {

    #FooterContainer {
        height: auto;
    }

    #FooterContainer span {
        line-height: 2;
    }

}